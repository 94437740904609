.popular {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px; /* Adjust gap between elements */
    padding: 30px; /* Add padding for spacing */
    overflow-x: hidden; /* Hide horizontal overflow */
  }
  
  .popular h1 {
    color: #FF4500;
    font-size: 2rem; /* Adjust font size */
    font-weight: 600;
  }
  
  .popular hr {
    width: 100%; /* Ensure full width */
    max-width: 200px; /* Limit max width */
    height: 4px;
    border-radius: 10px;
    background-color: #252525;
  }
  
  .popular-item {
    display: flex;
    justify-content: space-between; /* Distribute items evenly */
    gap: 20px; /* Adjust gap between items */
  }
  
  .popular-item img {
    max-width: 100%; /* Ensure images fill the container */
    height: auto; /* Maintain aspect ratio */
  }
  
  .popular-item p {
    margin: 0.5rem 0; /* Adjust spacing */
  }
  
  .item-price {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .price {
    color: #374151;
    font-size: 1rem;
    font-weight: 600;
  }
  
  .item:hover {
    transform: scale(1.05);
    transition: transform 0.6s;
  }
  
  /* Responsive adjustments */
  @media screen and (max-width: 768px) {
    .popular-item {
      flex-direction: column; /* Stack items vertically on smaller screens */
    }
  }
  