.gamedisplay-page {
  padding: 0;
  padding-top: 10px;
  background: #e6f5cd;
}

.gamedisplay {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 20px;
}

.gamedisplay-left {
  flex: 1;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center align all items horizontally */
}

.img-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.img-list img {
  width: 100px;
  height: 100px;
  margin: 5px;
}

.main-img img {
  width: 100%;
  max-height: 400px;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.gamedisplay-right {
  flex: 1;
}

h1 {
  font-weight: bold;
  color: #333;
  margin-top: 0;
}

.price-title,
.players-title,
.age-title,
.learning-outcomes-title {
  font-weight: bold;
}

.price {
  margin-top: 10px;
}

.learning-outcomes {
  white-space: pre-line;
}

.demo-link a {
  display: inline-block;
  margin-top: 15px;
  font-size: 18px;
  font-weight: bold;
  text-decoration: none;
  color: #00008B;
  cursor: pointer;
}

.demo-link-p {
  font-size: 12px;
  color: brown;
}

.gamedisplay-page button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #00008B;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.gamedisplay-page footer {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px 0;
  background-color: #343a40;
  color: #ffffff;
  font-size: 1rem;
  bottom: 0;
}

.gamedisplay-page footer p {
  margin: 0;
}

@media (min-width: 768px) {
  .main-img img {
    width: 100%;
    max-width: auto;
    max-height: auto;
    object-fit: contain;
    border-radius: 10px;
    box-shadow: 0 2% 3% rgba(0, 0, 0, 0.1);
  }
}

@media only screen and (max-width: 768px) {
  .gamedisplay {
    flex-direction: column;
  }

  .gamedisplay-left,
  .gamedisplay-right {
    margin-right: 0;
  }

  .main-img img {
    max-height: 300px;
  }
}
