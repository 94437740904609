.item {
    width: 100%; /* Ensure the item fills its container */
    max-width: 350px; /* Limit maximum width */
    min-height: 365px;
    margin: 0 auto; /* Center the item horizontally */
    overflow: hidden; /* Hide horizontal overflow */
    display: flex; /* Use flexbox for alignment */
    flex-direction: column; /* Stack elements vertically */
    align-items: center; /* Center items horizontally */
    background-color: #f0f0f0;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 20px;
  }
  
  .item img {
    max-width: 100%; /* Ensure images fill the container */
    height: auto; /* Maintain aspect ratio */
    border-radius: 5px;
  }
  
  .item p {
    text-align: center;
    margin: 0.5rem 0;
    font-size: 1.2rem;
    font-weight: bold;
    color: #333;
  }
  
  .item-price {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .price {
    color: #374151;
    font-size: 1rem;
    font-weight: 600;
  }
  
  .item:hover {
    transform: scale(1.05);
    transition: transform 0.6s;
  }