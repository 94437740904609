.image-slider-container {
  position: relative;
  width: 100%;
  max-width: 800px;
  /* Adjust the max-width as needed for a larger slider */
  margin: auto;
  overflow: hidden;
}

.heading-container {
  text-align: center;
  margin-bottom: 10px;
  /* Add more space below the heading */
}

.slider-heading {
  color: #FF4500;
  font-size: 2.5rem;
  font-weight: 600;
  margin: 0;
}

.separator {
  margin: 10px auto;
  /* Add some margin for visual separation */
  border: none;
  height: 4px;
  width: 80%;
  /* Ensure it's not too wide */
  border-radius: 10px;
  background-color: #252525;
}

.image-slider {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  /* Center the image and arrows */
  height: 400px;
  /* Fixed height to ensure stable size */
  border-radius: 15px;
  /* Ensure the image slider has rounded corners */
  overflow: hidden;
  /* Hide any overflow from the image */
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.slider-arrow {
  display: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  font-size: 3rem;
  /* Increase arrow size */
  color: #FF4500;
  /* Stylish color for arrows */
  cursor: pointer;
  z-index: 1;
}

.left-arrow {
  left: 10px;
  /* Position left arrow */
}

.right-arrow {
  right: 10px;
  /* Position right arrow */
}

.slider-image {
  width: auto;
  max-height: 100%;
  /* Ensure the image covers the container */
  object-fit: contain;
  /* Ensure the image is not cropped too much */
  border-radius: 15px;
  /* Keep rounded corners for the image */
}

.slider-dots {
  text-align: center;
  position: absolute;
  bottom: 10px;
  width: 100%;
}

.slider-dot {
  display: inline-block;
  width: 15px;
  /* Increase dot size */
  height: 15px;
  margin: 0 8px;
  /* Increase spacing between dots */
  background-color: #ccc;
  border-radius: 50%;
  cursor: pointer;
}

.slider-dot.active {
  background-color: #FF4500;
  /* Stylish color for active dot */
}

@media screen and (max-width: 768px) {
  .slider-heading {
    color: #FF4500;
    font-size: 2.0rem;
    font-weight: 600;
    margin: 0;
    margin-top: 20px;
    line-height: 1.3;
  }
}