.footer {
    width: 100%;
    padding: 4rem 0;
    background: #d3f58f;
    max-height: 100vh;
  }
  
  .contact-container {
    max-width: 1200px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    padding: 0 20px;
    box-sizing: border-box;
  }
  
  .contactUs-left,
  .contactUs-right {
    flex: 1 1 45%;
    margin: 10px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    box-sizing: border-box;
  }
  
  .contactUs-left {
    animation: slideInLeft 1s ease-out;
  }
  
  .contactUs-right {
    animation: slideInRight 1s ease-out;
  }
  
  @keyframes slideInLeft {
    from {
      opacity: 0;
      transform: translateX(-50%);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  @keyframes slideInRight {
    from {
      opacity: 0;
      transform: translateX(50%);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
  }
  
  .contact-form h2 {
    margin-bottom: 1.5rem;
    color: #333;
    font-size: 2rem;
    position: relative;
    display: inline-block;
  }
  
  .contact-form h2::after {
    content: '';
    width: 50px;
    height: 3px;
    background: #6b8e23; /* sage green accent */
    position: absolute;
    bottom: -5px;
    left: 0;
  }
  
  .form-group {
    margin-bottom: 1.5rem;
    position: relative;
  }
  
  .form-group input {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
  }

  .form-group textarea {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    height: 100px
  }
  
  .form-group label {
    position: absolute;
    top: -0.75rem;
    left: 1rem;
    background: #fff;
    padding: 0 0.25rem;
    color: #777;
  }
  
  .footer button {
    width: 100%;
    padding: 0.75rem;
    border: none;
    border-radius: 5px;
    background-color: #6b8e23; /* sage green button */
    color: #fff;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .footer button:hover {
    background-color: #556b2f; /* darker sage green on hover */
  }
  
  .contact-options {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .contact-options h2 {
    margin-bottom: 1.5rem;
    color: #333;
    font-size: 2rem;
    position: relative;
    display: inline-block;
  }
  
  .contact-options h2::after {
    content: '';
    width: 50px;
    height: 3px;
    background: #6b8e23; /* sage green accent */
    position: absolute;
    bottom: -5px;
    left: 0;
  }
  
  .contact-options-icons {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
  }
  
  .contact-option {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .contact-option:hover {
    transform: scale(1.05);
  }
  
  .contact-option i {
    font-size: 1.5rem;
    color: #6b8e23; /* sage green icons */
  }
  
  .contact-option span, .contact-option a {
    font-size: 1rem;
    color: #333;
    text-decoration: none;
    flex: 1;
  }
  
  .contact-option span:hover, .contact-option a:hover {
    text-decoration: none;
  }

  .contactUs-page footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    text-align: center;
    padding: 20px 0;
    background-color: #343a40;
    color: #ffffff;
    font-size: 1rem;
  }
  
  .contactUs-page footer p {
    margin: 0;
  }
  
  @media screen and (max-width: 768px) {

    .footer {
      width: 100%;
      padding: 4rem 0;
      background: #d3f58f;
      max-height: 120vh;
    }
    
    .contactUs-left,
    .contactUs-right {
      flex: 1 1 100%;
      padding: 1rem;
    }
  }
  
  @media screen and (max-width: 480px) {
    .form-group label {
      font-size: 0.75rem;
    }
  
    .footer button {
      font-size: 0.875rem;
    }
  
    .contact-options h2 {
      font-size: 1.25rem;
    }
  
    .contact-option i {
      font-size: 1.25rem;
    }
  
    .contact-option span, .contact-option a {
      font-size: 0.875rem;
    }
  }
  