.game-category {
    padding: 0;
    padding-top: 20px;
    width: 100%;
    box-sizing: border-box; /* Ensure padding doesn't affect overall width */
    display: flex;
    flex-direction: column;
    align-items: center;
    text-transform: capitalize;
    background: #EAE0C8;
    min-height: 100vh;
   
  }
  
  .game-banner {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .game-banner h1 {
    font-size: 2rem;
    color: #333;
  }
  
  .games {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .games .item {
    width: calc(33.33% - 10px); /* Adjust width for three items per row with gap */
    min-width: 350px; /* Set a minimum width for each item */
    background-color: #f0f0f0;
    border-radius: 10px;
    overflow: hidden;
    text-align: center;
    padding: 20px;
    box-sizing: border-box; /* Ensure padding doesn't affect overall width */
  }
  
  .games .item img {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
  }
  
  .games .item p {
    margin: 0.5rem 0;
    font-size: 1.2rem;
    font-weight: bold;
    color: #333;
  }
  
  .back {
    position: fixed;
    bottom: 20px;
    right: 20px;
    text-align: center;
  }
  
  .back a {
    text-decoration: none;
    color: #333;
  }
  
  .back i {
    font-size: 32px;
  }
  