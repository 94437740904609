/* Common styles */
.login-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 97vh; /* Adjusted height */
  padding: 20px;
  background-color: #f0f2f5;
}

.login-container {
  width: 100%;
  max-width: 450px; /* Increased width */
  padding: 30px; /* Reduced padding */
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-container h2 {
  margin: 0 0 10px 0; /* Reduced margin */
  font-size: 20px;
  color: #333;
}

.login-container p {
  margin: 0 0 15px 0; /* Reduced margin */
  font-size: 16px;
  color: #555;
}

.login-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px; /* Reduced gap */
}

.login-form .form-group {
  display: flex;
  flex-direction: column;
}

.login-form input {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.3s ease;
}

.login-form input:focus {
  border-color: #30308f;
  outline: none;
}

.login-form label {
  margin-bottom: 5px;
  font-size: 14px;
  color: #555;
}

.login-form button {
  width: 100%;
  padding: 12px;
  background-color: #00008b;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-form button:hover {
  background-color: #0056b3;
}

.or-separator {
  display: flex;
  align-items: center;
  margin: 15px 0; /* Reduced margin */
  font-size: 14px;
  font-weight: 600;
  color: #333;
}

.or-separator span {
  flex: 1;
  text-align: center;
}

.or-separator::before,
.or-separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #ccc;
  margin: 0 5px;
}

.social-login {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px; /* Reduced gap */
}

.social-login button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 12px;
  background-color: #ccc;
  color: #333;
  font-weight: 400;
  border: none;
  border-color: #357ae8;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.social-login button:hover {
  background-color: #aaa;
}

.social-login .google-logo {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.toggle-link {
  margin-top: 30px; /* Reduced margin */
  font-size: 16px;
  color: #555;
  text-align: center;
}

.toggle-link span {
  color: #00008b;
  cursor: pointer;
  text-decoration: none;
}

.success-message {
  margin-top: 15px; /* Reduced margin */
  font-size: 16px;
  color: green;
  text-align: center;
}

/* Media query for smaller screens */
@media (max-width: 480px) {
  .login-container {
    padding: 25px; /* Adjusted padding */
    max-width: 100%;
  }

  .login-container h2 {
    font-size: 20px;
  }

  .login-container p {
    font-size: 14px;
  }

  .login-form input,
  .login-form button,
  .social-login button {
    padding: 10px;
    font-size: 14px;
  }

  .toggle-link {
    font-size: 14px;
  }

  .success-message {
    font-size: 14px;
  }
}

/* Media query for medium screens */
@media (max-width: 768px) {
  .login-container {
    padding: 30px; /* Adjusted padding */
    max-width: 80%;
  }

  .login-container h2 {
    font-size: 22px;
  }

  .login-container p {
    font-size: 15px;
  }

  .login-form input,
  .login-form button,
  .social-login button {
    padding: 11px;
    font-size: 15px;
  }

  .toggle-link {
    font-size: 15px;
  }

  .success-message {
    font-size: 15px;
  }
}

