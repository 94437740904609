.prog-box {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    overflow: hidden;
    padding: 30px;
}

.background-half {
    position: fixed;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background: linear-gradient(45deg, #FFFACD, #FFFFE0);
    z-index: -1;
  }

.prog-heading h1 {
    text-align: justify;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #7e2210;
}

.prog-left {
    width: calc(50% - 10px);
    float: left;
    padding-right: 20px;
    box-sizing: border-box;
    height: 100%;
}

.prog-content {
    margin: 0;
    padding: 0;
    line-height: 1.5;
    text-align: left;
    /* Align paragraphs to the left */
}

.prog-content p {
    margin-bottom: 20px;
    animation: slideUp 0.5s ease;
    /* Smooth animation for paragraphs */
}

@keyframes slideUp {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-5px);
    }
}

.prog-right {
    margin-top: -30px;
    width: calc(50% - 5px);
    /* Adjust as needed, subtracting margin */
    float: right;
    /* Keep .prog-right to the right */
}

.prog-img {
    width: 100%;
    /* Ensure image slider takes full width */
    height: auto;
    /* Maintain aspect ratio */
}

.back {
    position: fixed;
    bottom: 20px;
    right: 20px;
    text-align: center;
}

.back a {
    text-decoration: none;
    color: #333;
}

.back i {
    font-size: 32px;
}

@media screen and (max-width: 768px) {
    .prog-heading h1 {
        text-align: center;
        font-size: 40px;
        margin-top: 20px;
        line-height: 1.3;
    }

    .prog-box {
        margin: 0px;
        padding: 0px 20px;
    }

    .prog-left{
        width: 100%;
        float: none;
        padding-right: 0;
        box-sizing: border-box;
        margin-bottom: 20px;
    }

    .prog-right {
        width: 100%;
        float: none;
        padding-right: 0;
        box-sizing: border-box;
        margin-top: 30px;
    }

    .prog-content p {
        font-size: 18px;
    }

    .prog-img {
        width: 100%;
        margin: 0px;
    }
}